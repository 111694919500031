
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Mulish';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {
  html, body {
    @apply bg-white; /* Set the background color for html and body */
    @apply h-full;   /* Ensure the body takes the full height of the viewport */
    @apply overscroll-none; /* Prevent overscrolling on mobile devices */
  }
}
